import React, { useEffect } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { getsettingsdata, updatesettingdata } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const levels = 15;

const validationSchema = yup.object({
  unilevel_status: yup
    .string()
    .required("Unilevel Status is required"),
  unilevel_bonus_level1: yup
    .string()
    .required("Unilevel Bonus Level1 is required"),
  unilevel_bonus_level2: yup
    .string()
    .required("Unilevel Bonus Level2 is required"),
  unilevel_bonus_level3: yup
    .string()
    .required("Unilevel Bonus Level3 is required"),
  unilevel_bonus_level4: yup
    .string()
    .required("Unilevel Bonus Level4 is required"),
  unilevel_bonus_level5: yup
    .string()
    .required("Unilevel Bonus Leve5 is required"),
  unilevel_bonus_level6: yup
    .string()
    .required("Unilevel Bonus Leve6 is required"),
  unilevel_bonus_level7: yup
    .string()
    .required("Unilevel Bonus Leve7 is required"),
  unilevel_bonus_level8: yup
    .string()
    .required("Unilevel Bonus Leve8 is required"),
  unilevel_bonus_level9: yup
    .string()
    .required("Unilevel Bonus Leve9 is required"),
  unilevel_bonus_level10: yup
    .string()
    .required("Unilevel Bonus Leve10 is required"),
  unilevel_bonus_level11: yup
    .string()
    .required("Unilevel Bonus Leve11 is required"),
  unilevel_bonus_level12: yup
    .string()
    .required("Unilevel Bonus Leve12 is required"),
  unilevel_bonus_level13: yup
    .string()
    .required("Unilevel Bonus Leve13 is required"),
  unilevel_bonus_level14: yup
    .string()
    .required("Unilevel Bonus Leve14 is required"),
  unilevel_bonus_level15: yup
    .string()
    .required("Unilevel Bonus Leve15 is required"),
});

const Managecommission = () => {
  const [commissiondata, setCommissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const fetchcommissiondata = () => {
    const params = {
      keynames:
        "'unilevel_status','unilevel_bonus_level1', 'unilevel_bonus_level2', 'unilevel_bonus_level3', 'unilevel_bonus_level4', 'unilevel_bonus_level5', 'unilevel_bonus_level6', 'unilevel_bonus_level7', 'unilevel_bonus_level8', 'unilevel_bonus_level9', 'unilevel_bonus_level10', 'unilevel_bonus_level11', 'unilevel_bonus_level12', 'unilevel_bonus_level13', 'unilevel_bonus_level14', 'unilevel_bonus_level15'",
    };
    getsettingsdata(params, (response) => {
      setCommissionData(response?.data?.data?.values);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchcommissiondata();
  }, []);

  const handleSubmit = (data, setSubmitting) => {
    const newData = {
      obj: {
        unilevel_status: data.unilevel_status,
        unilevel_bonus_level1: data.unilevel_bonus_level1,
        unilevel_bonus_level2: data.unilevel_bonus_level2,
        unilevel_bonus_level3: data.unilevel_bonus_level3,
        unilevel_bonus_level4: data.unilevel_bonus_level4,
        unilevel_bonus_level5: data.unilevel_bonus_level5,
        unilevel_bonus_level6: data.unilevel_bonus_level6,
        unilevel_bonus_level7: data.unilevel_bonus_level7,
        unilevel_bonus_level8: data.unilevel_bonus_level8,
        unilevel_bonus_level9: data.unilevel_bonus_level9,
        unilevel_bonus_level10: data.unilevel_bonus_level10,
        unilevel_bonus_level11: data.unilevel_bonus_level11,
        unilevel_bonus_level12: data.unilevel_bonus_level12,
        unilevel_bonus_level13: data.unilevel_bonus_level13,
        unilevel_bonus_level14: data.unilevel_bonus_level14,
        unilevel_bonus_level15: data.unilevel_bonus_level15,
      },
    };
    updatesettingdata(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={"Manage Commission"} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                initialValues={{
                  unilevel_status: commissiondata[0]?.keyvalue || "",
                  unilevel_bonus_level1: commissiondata[1].keyvalue,
                  unilevel_bonus_level2: commissiondata[2].keyvalue,
                  unilevel_bonus_level3: commissiondata[3].keyvalue,
                  unilevel_bonus_level4: commissiondata[4].keyvalue,
                  unilevel_bonus_level5: commissiondata[5].keyvalue,
                  unilevel_bonus_level6: commissiondata[6].keyvalue,
                  unilevel_bonus_level7: commissiondata[7].keyvalue,
                  unilevel_bonus_level8: commissiondata[8].keyvalue,
                  unilevel_bonus_level9: commissiondata[9].keyvalue,
                  unilevel_bonus_level10: commissiondata[10].keyvalue,
                  unilevel_bonus_level11: commissiondata[11].keyvalue,
                  unilevel_bonus_level12: commissiondata[12].keyvalue,
                  unilevel_bonus_level13: commissiondata[13].keyvalue,
                  unilevel_bonus_level14: commissiondata[14].keyvalue,
                  unilevel_bonus_level15: commissiondata[15].keyvalue,
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  console.log("formikformdata", data);
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting);
                }}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography sx={{ pt: 1, pl: 1 }}>
                            Unilevel Status
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.unilevel_status === "On"} // Check if referral_commission_status is 'On'
                                onChange={(e) =>
                                  setFieldValue(
                                    "unilevel_status",
                                    e.target.checked ? "On" : "Off"
                                  )
                                } // Set 'On' or 'Off' based on the switch state
                                name="unilevel_status"
                                color="primary"
                                value={values.unilevel_status} // Set value as 'On' or 'Off'
                              />
                            }
                            label={values.unilevel_status} // Display 'On' or 'Off' based on the switch state
                          />
                        </Grid>
                      </Grid>
                    </Div>
                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Grid container spacing={2}>
                        {Array.from({ length: levels }, (_, index) => (
                          <Grid item xs={6} key={index}>
                            <JumboTextField
                              fullWidth
                              name={`unilevel_bonus_level${index + 1}`}
                              label={`Unilevel Bonus Level ${index + 1} (%)`}
                              type="text"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default Managecommission;
